import { FC, useEffect } from "react";

// MUI
import { Grid, IconButton, Typography } from "@mui/material";

// types
import { IPagination } from "../../helpers/types";
import { IStaffType } from "../../redux/staff/staff.actions";

// components
import BaseSelect from "../base/BaseSelect";

// icons
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";
import { useTranslation } from "react-i18next";

type IPaginationCardProps = {
  pagination: IPagination;
  setPagination: any;
  data: IStaffType | null;
};

const PaginationCard: FC<IPaginationCardProps> = ({
  pagination,
  setPagination,
  data,
}) => {
  // hooks
  const { t } = useTranslation();
  const pageCount =
    Math.ceil((data?.count as number) / pagination.pageSize) || 1;

  const handlePage = (num: number) => {
    setPagination({ ...pagination, pageNumber: pagination.pageNumber + num });
  };

  const handleLimit = (e: any) => {
    setPagination({ ...pagination, pageSize: e.target.value });
  };

  useEffect(() => {
    if (pagination.pageSize > (data?.count as number))
      setPagination({ ...pagination, pageNumber: 1 });
  }, [pagination.pageSize]);

  return (
    <Grid
      container
      gap={2}
      alignItems="center"
      height="100%"
      px={1.5}
      flexWrap="nowrap"
    >
      <Grid
        item
        xs={5}
        container
        bgcolor="white"
        alignItems="center"
        justifyContent="space-between"
        p={0.25}
        borderRadius={2}
      >
        <IconButton
          sx={{
            borderRadius: "5px 0 0 5px",
            bgcolor: "primary.main",
            svg: {
              path: { stroke: "white" },
            },
            ":disabled": {
              bgcolor: "primary.light",
            },
          }}
          onClick={() => handlePage(1)}
          disabled={pagination.pageNumber === 1}
        >
          <ArrowLeftIcon />
        </IconButton>
        <Typography color="primary" fontSize={13} fontWeight={500}>
          {pagination.pageNumber} {t("of")} {pageCount}
        </Typography>
        <IconButton
          sx={{
            borderRadius: "0 5px 5px 0",
            bgcolor: "primary.main",
            svg: {
              path: { stroke: "white" },
            },
            ":disabled": {
              bgcolor: "primary.light",
            },
          }}
          onClick={() => handlePage(-1)}
          disabled={pagination.pageNumber === pageCount}
        >
          <ArrowRightIcon />
        </IconButton>
      </Grid>
      <Grid item xs={3}>
        <BaseSelect
          value={pagination.pageSize}
          onChange={handleLimit}
          border={2}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography fontSize={13} color="white">
          {t("of")} {data?.data.length} {t("entries")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PaginationCard;
