import { FC, ReactNode } from "react";
//MUI
import { Grid } from "@mui/material";

type IThreeSectionLayoutProps = {
  header?: JSX.Element;
  children?: ReactNode;
  footer?: JSX.Element;
  bgcolor?: string;
};

const ThreeSectionLayout: FC<IThreeSectionLayoutProps> = ({
  header,
  footer,
  children,
  bgcolor = "listBackground.main",
}) => {
  return (
    <Grid display="grid" gridTemplateRows="56px 1fr 56px">
      <Grid bgcolor="#F3F0FF">{header}</Grid>
      <Grid p={1.5} sx={{ overflowY: "auto", height: "calc(100vh - 3*56px)" }}>
        {children}
      </Grid>
      <Grid bgcolor="primary.main">{footer}</Grid>
    </Grid>
  );
};

export default ThreeSectionLayout;
