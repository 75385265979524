import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { IStaffData } from "../../redux/staff/staff.actions";

// icons
import { ReactComponent as EditIcon } from "../../assets/icons/edit-icon.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash-icon.svg";
import StaffCard from "./Staff.cards";

type IStaffListCardProps = {
  staff: IStaffData;
  selected: number;
  setSelected: any;
};

const StaffListCard: FC<IStaffListCardProps> = ({
  staff,
  selected,
  setSelected,
}) => {
  //hooks
  const navigate = useNavigate();

  // states
  // const [selected, setSelected] = useState(0);

  const handleEditStaff = () => {
    navigate(`/staffs/edit-staff/${staff.staffId}`);
  };

  const selectedStaff = () => {
    setSelected((prev: number) => (prev === staff.staffId ? 0 : staff.staffId));
  };

  return (
    <Accordion
      sx={{
        borderRadius: "8px !important",
        overflow: "hidden",
        m: "0 !important",
        boxShadow: "0px 1px 4px 0px #00000840",
        ".MuiAccordionSummary-content": {
          my: 0,
        },
      }}
      expanded={selected === staff.staffId}
      onChange={selectedStaff}
    >
      <Grid
        container
        bgcolor="white"
        p={0.75}
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        flexWrap="nowrap"
        height={48}
      >
        <AccordionSummary sx={{ p: 0, minHeight: "unset" }}>
          <Grid item flexBasis={40} height={40}>
            <img
              src={`http://booking.weeorder.co.uk/uploadfiles/Images/${staff.staffImage}`}
              alt=""
              width={40}
              height="100%"
              style={{ borderRadius: 4 }}
            />
          </Grid>
        </AccordionSummary>

        <Grid item flex={1}>
          <Typography fontSize={12}>{staff.displayName}</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          bgcolor={staff.staffStatus ? "success.main" : "error.main"}
          container
          alignItems="center"
          justifyContent="center"
          borderRadius={1}
        >
          <Typography fontSize={12} color="white" py={0.25}>
            {staff.staffStatus ? "active" : "notActive"}
          </Typography>
        </Grid>
        <Grid item flexBasis="content" container gap={2}>
          <EditIcon onClick={handleEditStaff} />
          <TrashIcon />
        </Grid>
      </Grid>
      <Divider sx={{ mx: 0.75 }} />

      <StaffCard staff={staff} />
    </Accordion>
  );
};

export default StaffListCard;
