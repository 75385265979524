import { FC } from "react";
import { RotatingLines } from "react-loader-spinner";

// MUI
import {
  FormControl,
  Select,
  MenuItem,
  SelectProps,
  InputLabel,
  Typography,
  Box,
} from "@mui/material";

// types
import { IInputType } from "../../helpers/types";

// icons
import { ReactComponent as SelectIcon } from "../../assets/icons/select-icon.svg";

interface IBaseSelect extends SelectProps {
  onChange: any;
  value: any;
  inputItems?: IInputType[];
  defaultVal?: string;
  border?: string | number;
  bgcolor?: string;
  isPaginate?: boolean;
  totalPage?: number;
  isFetching?: boolean;
  label?: any;
}

const paginateItems = [
  {
    id: 1,
    title: "10 items",
    value: 10,
  },
  {
    id: 2,
    title: "20 items",
    value: 20,
  },
  {
    id: 3,
    title: "50 items",
    value: 50,
  },
  {
    id: 4,
    title: "100 items",
    value: 100,
  },
];

const BaseSelect: FC<IBaseSelect> = ({
  value,
  inputItems = paginateItems,
  defaultVal,
  border = 0,
  bgcolor = "inputBackground.main",
  isPaginate = false,
  totalPage,
  isFetching,
  label,
  sx,
  onChange,
  ...otherProps
}) => {
  return (
    <FormControl
      fullWidth
      sx={{
        ".MuiInputBase-root": {
          borderRadius: 2,
          bgcolor,
        },
      }}
    >
      {value ? <InputLabel>{label}</InputLabel> : null}
      <Select
        label={value ? label : ""}
        value={value}
        displayEmpty
        sx={{
          "& .MuiInputBase-input": {
            p: 0.625,
            display: "flex",
            alignItems: "center",
          },
          ".MuiSelect-select": {
            color: "primary.main",
            fontWeight: 500,
            fontSize: 16,
          },
          ".MuiSelect-icon": {
            top: "50%",
            transform: "translateY(-50%)",
          },
          svg: {
            // zIndex: 10,
            color: "primary.main",
          },
          minHeight: 30,
          ...sx,
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: "5px",
              maxHeight: 300,
            },
          },
        }}
        IconComponent={SelectIcon}
        onChange={onChange}
        {...otherProps}
      >
        {defaultVal && (
          <MenuItem value={0} sx={{ fontSize: 14 }}>
            {defaultVal}
          </MenuItem>
        )}
        {!isFetching ? (
          inputItems.map((item) => {
            return (
              <MenuItem key={item.id} value={item.value}>
                <Box display="flex">
                  <Typography fontSize={12} fontWeight={500}>
                    {isPaginate ? "Page " + item.id : item.title}
                  </Typography>
                  <Typography
                    fontSize={14}
                    display={{ xs: "none", md: "block" }}
                    fontWeight={500}
                  >
                    {isPaginate && " of " + totalPage}
                  </Typography>
                </Box>
              </MenuItem>
            );
          })
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={100}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={true}
            />
          </Box>
        )}
      </Select>
    </FormControl>
  );
};

export default BaseSelect;
