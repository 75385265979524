import { useState } from "react";
import { Route, Routes } from "react-router-dom";

// types
import { IPagination } from "../../helpers/types";

// pages
import ListStaff from "./List.staffs";
import FormStaff from "./Form.staffs";

const Staff = () => {
  const [pagination, setPagination] = useState<IPagination>({
    pageNumber: 1,
    pageSize: 10,
  });

  return (
    <Routes>
      <Route
        element={
          <ListStaff pagination={pagination} setPagination={setPagination} />
        }
        path="/"
      />
      <Route element={<FormStaff />} path="/create-staff" />
      {/* <Route element={<OverviewStaff />} path="/edit-staff/:staffId/*" /> */}
    </Routes>
  );
};

export default Staff;
