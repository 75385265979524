import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

// MUI
import { Grid, Typography, FormControlLabel, Switch } from "@mui/material";

// components
import BaseInput from "../../components/base/BaseInput";
import BaseFileInput from "../../components/base/BaseFileInput";
import BaseTextInput from "../../components/base/BaseTextInput";
import BaseButton from "../../components/base/BaseButton";

// helpers
import { useStaffForm } from "../../helpers/formik";

// icons
import { ReactComponent as ImageInputIcon } from "../../assets/icons/image-input-icon.svg";

const FormStaff = () => {
  // hooks
  const { t } = useTranslation();
  const navigate = useNavigate();

  // states
  const [attachment, setAttachment] = useState();

  const { values, handleChange } = useStaffForm(null);
  return (
    <Grid
      container
      gap={1.5}
      justifyContent="center"
      p={1.5}
      alignContent="start"
      overflow="auto"
    >
      <Grid
        item
        xs={12}
        container
        position="fixed"
        bottom={0}
        zIndex={100}
        bgcolor="primary.main"
        p={1.5}
        height={56}
        left={0}
        alignItems="center"
        gap={1.5}
        flexWrap="nowrap"
      >
        <Grid item xs={6}>
          <BaseButton
            sx={{
              bgcolor: "white",
              color: "primary.main",
              "&:hover": {
                bgcolor: "white",
              },
              border: 0,
              fontWeight: 500,
            }}
            // onClick={() => handleSubmit()}
            // disabled={isPostingStaff}
          >
            {!true ? (
              <Grid
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: 10,
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="30"
                  visible
                />
              </Grid>
            ) : (
              "Save"
            )}
          </BaseButton>
        </Grid>

        <Grid item xs={6}>
          <BaseButton
            sx={{
              boxShadow: 0,
            }}
            onClick={() => navigate(-1)}
          >
            Discard
          </BaseButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" fontSize={14} fontWeight={500}>
          {t("staffPersonal")}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <BaseFileInput
          attachment={attachment}
          setAttachment={setAttachment}
          icon={<ImageInputIcon />}
        />
      </Grid>
      <Grid item xs={12}>
        <BaseInput
          value={values.StaffName}
          onChange={handleChange}
          variant="outlined"
          label="First Name"
          name="StaffName"
        />
      </Grid>
      <Grid item xs={12}>
        <BaseInput
          value={values.StaffFamily}
          onChange={handleChange}
          variant="outlined"
          label="Last Name"
          name="StaffFamily"
        />
      </Grid>
      <Grid item xs={12}>
        <BaseInput
          value={values.DisplayName}
          onChange={handleChange}
          variant="outlined"
          label="Nick Name"
          name="DisplayName"
        />
      </Grid>
      <Grid item xs={12} position="relative">
        <BaseTextInput
          name="Bio"
          onChange={handleChange}
          value={values.Bio}
          label="Biography"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" fontSize={14} fontWeight={500}>
          {t("staffContact")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <BaseInput
          value={values.Mobile}
          onChange={handleChange}
          variant="outlined"
          label="Phone Number"
          name="Mobile"
        />
      </Grid>
      <Grid item xs={12}>
        <BaseInput
          value={values.StaffEmail}
          onChange={handleChange}
          variant="outlined"
          label="Phone Number"
          name="StaffEmail"
        />
      </Grid>
      <Grid item xs={12} position="relative">
        <BaseTextInput
          name="Address"
          onChange={handleChange}
          value={values.Address}
          label="Address"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" fontSize={14} fontWeight={500}>
          {t("staffCalendar")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        position="relative"
        bgcolor="inputBackground.main"
        p={1}
        height={42}
        borderRadius={2}
        border={1}
        borderColor="primary.main"
      >
        <Typography color="primary" fontSize={14} pl={1}>
          color
        </Typography>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            gap: 1,
            bgcolor: "#fff",
            position: "absolute",
            top: "50%",
            right: 10,
            transform: "translateY(-50%)",
            borderRadius: "5px",
            p: 0.2,
            minWidth: 125,
            "#input-color::-webkit-color-swatch": {
              border: "none",
              borderRadius: "5px",
              padding: 0,
            },
          }}
        >
          <Typography fontSize={14}>{values.StaffCalendarColor}</Typography>
          <input
            type="color"
            style={{
              border: 0,
              backgroundColor: "transparent",
              width: 30,
              height: 30,
              borderRadius: 5,
            }}
            id="input-color"
            value={values.StaffCalendarColor}
            onChange={handleChange}
            name="StaffCalendarColor"
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" fontSize={14} fontWeight={500}>
          {t("staffNotification")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          label={
            <Typography fontSize={12} fontWeight={400} color="primary">
              SMS
            </Typography>
          }
          control={
            <Switch
              checked={values.NotiFicationSms}
              name="NotiFicationSms"
              onChange={handleChange}
              size="small"
            />
          }
          labelPlacement="start"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            mx: 0,
            bgcolor: "inputBackground.main",
            p: 1,
            borderRadius: 2,
            border: 1,
            borderColor: "primary.main",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          label={
            <Typography fontSize={12} fontWeight={400} color="primary">
              Email
            </Typography>
          }
          control={
            <Switch
              checked={values.NotificationEmail}
              name="NotificationEmail"
              onChange={handleChange}
              size="small"
            />
          }
          labelPlacement="start"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            mx: 0,
            bgcolor: "inputBackground.main",
            p: 1,
            borderRadius: 2,
            border: 1,
            borderColor: "primary.main",
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" fontSize={14} fontWeight={500}>
          {t("staffStatus")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={values.IsOnlineBookable}
              size="small"
              name="IsOnlineBookable"
              onChange={handleChange}
            />
          }
          label={
            <Typography fontSize={12} fontWeight={400} color="primary">
              Is Online Bookable
            </Typography>
          }
          labelPlacement="start"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            mx: 0,
            bgcolor: "inputBackground.main",
            p: 1,
            borderRadius: 2,
            border: 1,
            borderColor: "primary.main",
          }}
        />
      </Grid>
      <Grid item xs={12} mb={7}>
        <FormControlLabel
          control={
            <Switch
              checked={values.IsActive}
              size="small"
              name="IsActive"
              onChange={handleChange}
            />
          }
          label={
            <Typography fontSize={12} fontWeight={400} color="primary">
              Active
            </Typography>
          }
          labelPlacement="start"
          sx={{
            width: "100%",
            justifyContent: "space-between",
            mx: 0,
            bgcolor: "inputBackground.main",
            p: 1,
            borderRadius: 2,
            border: 1,
            borderColor: "primary.main",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default FormStaff;
