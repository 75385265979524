import { FC } from "react";

// MUI
import { Grid } from "@mui/material";

// components
import AppDrawer from "../../layout/AppDrawer";

// icons
import { ReactComponent as Logo } from "../../assets/icons/logo-icon.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/notifications-icon.svg";
import { ReactComponent as MailIcon } from "../../assets/icons/mail-icon.svg";

// types
import { RouteType } from "../../App";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { changeDir } from "../../redux/user/user.reducer";
import { useTranslation } from "react-i18next";

export type IMainHeader = {
  routes: RouteType[];
};

const MainHeader: FC<IMainHeader> = ({ routes }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();
  const changeDirRtl = () => {
    dispatch(changeDir("rtl"));
    i18n.changeLanguage("fa");
    document.dir = "rtl";
  };
  const changeDirLtr = () => {
    dispatch(changeDir("ltr"));
    i18n.changeLanguage("en");
    document.dir = "ltr";
  };

  return (
    <Grid
      p={1.5}
      display="grid"
      gridTemplateColumns="32px 46px 1fr 32px 32px"
      bgcolor="primary.main"
      alignContent="center"
      gap={1.5}
    >
      <AppDrawer routes={routes} />
      <Logo />
      <Grid />
      <NotificationIcon onClick={changeDirRtl} />
      <MailIcon onClick={changeDirLtr} />
    </Grid>
  );
};

export default MainHeader;
