import React, { useState, FC, KeyboardEvent, MouseEvent } from "react";

// MUI
import { Grid, Drawer, IconButton, Typography } from "@mui/material";
import { ReactComponent as MenuIcon } from "../assets/icons/menu-icon.svg";
import { useAppSelector } from "../hooks/redux.hooks";
import { RouteType } from "../App";
import { useLocation, useNavigate } from "react-router-dom";

type Anchor = "left" | "right";

type IDrawer = {
  routes: RouteType[];
};

const AppDrawer: FC<IDrawer> = ({ routes }) => {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();

  // states
  const [state, setState] = useState({
    left: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const { user } = useAppSelector((state) => state.user);

  const navigateTo = (url: string) => {
    navigate(url);
  };

  const list = (anchor: Anchor) => (
    <Grid
      width={230}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      display="grid"
      gridTemplateRows="100px 1fr"
      gap={3}
    >
      <Grid
        p={1.5}
        container
        flexWrap="nowrap"
        gap={1.5}
        sx={{ backgroundImage: "url(/banner.jpg)" }}
        alignItems="center"
      >
        <Grid item flexBasis={77}>
          <img src="/user.jpg" alt="" />
        </Grid>
        <Grid item flex={1} container flexDirection="column">
          <Typography>{user?.firstName}</Typography>
          <Typography>{user?.fullname}</Typography>
        </Grid>
      </Grid>
      <Grid container px={3} gap={3} sx={{ overflowY: "auto" }}>
        {routes.map((item) => {
          const isActive = location.pathname === item.url;

          return (
            <Grid
              key={item.id}
              item
              xs={12}
              container
              gap={1.5}
              alignItems="center"
              onClick={() => navigateTo(item.url)}
            >
              <IconButton
                sx={{
                  p: 0,
                  svg: {
                    width: 16,
                    height: 16,
                    path: { fill: isActive ? "#785FDC" : null },
                  },
                }}
              >
                {item.icon}
              </IconButton>
              <Typography
                textTransform="capitalize"
                fontSize={12}
                fontWeight={isActive ? 500 : 400}
                color={isActive ? "primary.main" : "gey.800"}
              >
                {item.name}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );

  return (
    <>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)} sx={{ p: 0 }}>
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
};

export default AppDrawer;
