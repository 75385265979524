import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuthForm } from "../helpers/formik";
import { AppDispatch } from "../redux/store";
import { loginUser } from "../redux/user/user.actions";
import { useNavigate } from "react-router-dom";

// MUI
import { Grid, Typography, InputAdornment, IconButton } from "@mui/material";
import BaseInput from "../components/base/BaseInput";
import BaseButton from "../components/base/BaseButton";

// icons
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ReactComponent as EyeIcon } from "../assets/icons/eye-icon.svg";
import { useAppSelector } from "../hooks/redux.hooks";
import { RotatingLines } from "react-loader-spinner";
import { clearLoginError } from "../redux/user/user.reducer";

const LoginPage = () => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // states
  const [showPass, setShowPass] = useState<boolean>(false);
  const { isLoggingIn, loginError } = useAppSelector((state) => state.user);

  const showPassHandler = () => {
    setShowPass(!showPass);
  };

  const handleKeyPress = (e: any) => {
    const parameters = {
      body: values,
      navigate,
    };
    validateForm();

    if (e.key === "Enter") isValid && dispatch(loginUser(parameters));
  };

  const submitHandler = () => {
    const parameters = {
      body: values,
      navigate,
    };
    validateForm();
    isValid && dispatch(loginUser(parameters));
  };
  const { values, handleChange, handleSubmit, validateForm, isValid, errors } =
    useAuthForm(submitHandler);

  useEffect(() => {
    dispatch(clearLoginError());
  }, []);

  return (
    <Grid
      container
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: "url(./background-mobile.jpg)",
      }}
    >
      <Grid
        item
        xs={11}
        md={5}
        borderRadius={2}
        container
        justifyContent="center"
        gap={2}
        bgcolor="white"
        position="relative"
        px={4}
        pb={2}
        overflow="hidden"
        sx={{ maxWidth: "500px !important" }}
      >
        <Grid
          bgcolor="primary.main"
          position="absolute"
          height={50}
          width="100%"
          top={0}
          borderRadius={2}
        />
        <Grid
          item
          xs={12}
          bgcolor="primary.main"
          py={1}
          pt={1.25}
          borderRadius={2}
          zIndex={1}
        >
          <Typography color="white" fontWeight={400} fontSize={20}>
            Sign In
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            height: loginError ? 24 : 0,
            transition: "height 0.5s ease",
          }}
        >
          <Typography color="error.main" fontSize={14} variant="h5">
            {loginError}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <BaseInput
            variant="outlined"
            value={values.userName}
            onChange={handleChange}
            name="userName"
            placeholder={errors.userName || "username"}
            bgcolor="#F3F0FF"
            sx={{ fieldset: { borderRadius: 2 } }}
            p={2}
            onKeyPress={handleKeyPress}
            err={errors.userName}
            color={errors.userName ? "error" : "primary"}
          />
        </Grid>
        <Grid item xs={12}>
          <BaseInput
            variant="outlined"
            value={values.userPass}
            onChange={handleChange}
            name="userPass"
            placeholder={errors.userPass || "Password"}
            bgcolor="#F3F0FF"
            sx={{ fieldset: { borderRadius: 2 } }}
            p={2}
            type={showPass ? "text" : "password"}
            onKeyPress={handleKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ zIndex: 1 }}>
                  <IconButton
                    onClick={showPassHandler}
                    sx={{ svg: { width: 24, height: 24 } }}
                  >
                    {showPass ? <LockOutlinedIcon /> : <EyeIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            err={errors.userPass}
            color={errors.userPass ? "error" : "primary"}
          />
        </Grid>
        <Grid item xs={12}>
          <BaseButton
            type="submit"
            onClick={() => handleSubmit()}
            variant="outlined"
            sx={{ height: 40 }}
          >
            <RotatingLines
              strokeColor="#785FDC"
              strokeWidth="4"
              animationDuration="1"
              width="30"
              visible={isLoggingIn ? true : false}
            />
            Login
          </BaseButton>
        </Grid>

        <Grid item xs={12}>
          <Typography color="primary.main" fontSize={14} variant="h5">
            Forget Password?
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
