import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useAppSelector } from "../../hooks/redux.hooks";
import { useTranslation } from "react-i18next";

// types
import { IPagination } from "../../helpers/types";

// MUI
import { Grid, Skeleton, Typography } from "@mui/material";

// redux
import { fetchAllStaffs } from "../../redux/staff/staff.actions";

// components
import ThreeSectionLayout from "../../layout/ThreeSection.layout";
import StaffListCard from "../../components/cards/StaffList.cards";
import StaffListHeader from "../../components/headers/StaffList.headers";

// helpers
import { listDummyData } from "../../helpers/data";

// icons
import { ReactComponent as NoDataIcon } from "../../assets/icons/no-data-icon.svg";
import PaginationCard from "../../components/cards/Pagination.cards";

type IListStaffProps = {
  pagination: IPagination;
  setPagination: any;
};

const ListStaff: FC<IListStaffProps> = ({ pagination, setPagination }) => {
  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  // search states
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("displayName");
  const staffStatus = searchParams.get("staffStatus");
  const isOnlineBookable = searchParams.get("isOnlineBookable");

  const { staffsData, isFetchingStaff } = useAppSelector(
    (state) => state.staff
  );
  const [selected, setSelected] = useState(0);

  const staffs = staffsData?.data;

  const parameters = {
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
  };

  useEffect(() => {
    dispatch(
      fetchAllStaffs({
        ...parameters,
        displayName: search,
        staffStatus,
        isOnlineBookable,
      })
    );
  }, [
    pagination.pageNumber,
    pagination.pageSize,
    search,
    staffStatus,
    isOnlineBookable,
  ]);

  return (
    <ThreeSectionLayout
      header={<StaffListHeader />}
      footer={
        <PaginationCard
          pagination={pagination}
          setPagination={setPagination}
          data={staffsData}
        />
      }
    >
      <Grid container gap={0.75}>
        {isFetchingStaff ? (
          listDummyData.map((_, idx) => {
            return (
              <Skeleton
                key={idx}
                animation="wave"
                width="100%"
                height={48}
                sx={{ transform: "scale(1)", bgcolor: "rgb(0 0 0 / 20%)" }}
              />
            );
          })
        ) : staffs && staffs.length > 0 ? (
          staffs.map((staff) => {
            return (
              <StaffListCard
                key={staff.staffId}
                staff={staff}
                selected={selected}
                setSelected={setSelected}
              />
            );
          })
        ) : (
          <Typography
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            fontWeight={500}
            color="grey.700"
            fontSize={16}
            height="100%"
            flexDirection="column"
            sx={{
              svg: {
                width: 35,
                height: 35,
              },
            }}
          >
            <NoDataIcon />
            {t("noData")}
          </Typography>
        )}
      </Grid>
    </ThreeSectionLayout>
  );
};

export default ListStaff;
