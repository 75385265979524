import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// MUI
import { Grid, IconButton } from "@mui/material";
import BaseButton from "../base/BaseButton";

// icons
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter-icon.svg";
import { ReactComponent as ExportIcon } from "../../assets/icons/export-icon.svg";

const StaffListHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToAddStaff = () => {
    navigate("/staffs/create-staff");
  };

  return (
    <Grid
      display="grid"
      gap={2}
      gridTemplateColumns="auto repeat(3, 32px)"
      alignItems="center"
      p={1.5}
    >
      <BaseButton sx={{ height: 32 }} onClick={navigateToAddStaff}>
        {t("addStaff")}
      </BaseButton>
      <IconButton
        sx={{ bgcolor: "white", borderRadius: 2, height: 32, width: 32 }}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        sx={{ bgcolor: "white", borderRadius: 2, height: 32, width: 32 }}
      >
        <FilterIcon />
      </IconButton>
      <IconButton
        sx={{ bgcolor: "white", borderRadius: 2, height: 32, width: 32 }}
      >
        <ExportIcon />
      </IconButton>
    </Grid>
  );
};

export default StaffListHeader;
