import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosConfig from "../../axiosConfig";
import { IEditActionsApiMethodType } from "../../helpers/types";
import { clearStaffServiceById } from "./staff.reducer";
import { toast } from "react-toastify";

type IStaffParams = {
  pageNumber?: number;
  pageSize?: number;
  staffStatus?: any;
  isOnlineBookable?: any;
  displayName?: any;
};

export type IStaffData = {
  staffId: number;
  shopId: number;
  staffName: string;
  staffFamil: string;
  displayName: string;
  staffEmail: string;
  mobile: string;
  staffStatus: number;
  staffImage: string;
  staffCalendarColor: string | null;
  isOnlineBookable: boolean;
  payType: string;
  annualSalary: number;
  weeklyHours: number;
  hourlyRate: number;
  extraTimeHourlyRate: number;
  description: string | null;
  isActive: boolean;
  address: string | null;
  bio: string | null;
  memberOfId: number;
  notificationEmail: boolean;
  notiFicationSms: boolean;
  payTypeId: number;
};

export type IStaffType = {
  count: number;
  data: IStaffData[];
  id?: number;
};

export const fetchAllStaffs = createAsyncThunk(
  "staffs/fetchAllStaffs",
  async (parameters: IStaffParams, ThunkApi: any) => {
    const { pageNumber, pageSize, displayName, isOnlineBookable, staffStatus } =
      parameters;
    const { shopId } = ThunkApi.getState().user.user;
    try {
      const baseUrl = `Staff/${pageNumber || 1}/${pageSize || 1000}/${shopId}`;
      const requestUrlSearchParams = new URLSearchParams();

      if (staffStatus) {
        requestUrlSearchParams.set("staffStatus", staffStatus);
      }
      if (displayName) {
        requestUrlSearchParams.set("displayname", displayName);
      }
      if (isOnlineBookable) {
        requestUrlSearchParams.set("isonlinebookable", isOnlineBookable);
      }

      const requestUrl = `${baseUrl}${
        requestUrlSearchParams.toString()
          ? `?${requestUrlSearchParams.toString()}`
          : ""
      }`;

      const { data } = await axiosConfig.get<IStaffType>(requestUrl);

      return data as IStaffType;
    } catch (error: any) {
      console.log(error);

      return error.response.resultMessage;
    }
  }
);

export type IPostStaffParams = {
  type: IEditActionsApiMethodType;
  body: Object;
};

export const postStaff = createAsyncThunk(
  "staffs/postStaff",
  async (parameters: IPostStaffParams, ThunkApi) => {
    const { type, body } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type]("Staff", body);
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);

export type IDeleteStaffParams = {
  staffId: number;
  fetchParams: IStaffParams;
};

export const deleteStaff = createAsyncThunk(
  "staffs/deleteStaff",
  async (parameters: IDeleteStaffParams, ThunkApi) => {
    const { staffId, fetchParams } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(`Staff/${staffId}`);
      ThunkApi.dispatch(fetchAllStaffs(fetchParams));
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error.response.resultMessage;
    }
  }
);
export type IGetStaffServicesParams = {
  staffId: number;
  pageNumber?: number;
  pageSize?: number;
};

export type IStaffServicesDataType = {
  id: number;
  serviceId: number;
  staffId: number;
  shopId: number;
  staffServiceAmount: number;
  staffServiceDuration: number;
  serviceTitle: string;
  serviceAmount: number;
  serviceDuration: number;
  staffDisplayName: string;
  staffEmail: null | string;
};

export const fetchStaffAllServices = createAsyncThunk(
  "staffs/fetchAllStaffServices",
  async (parameters: IGetStaffServicesParams, ThunkApi) => {
    const { staffId, pageNumber, pageSize } = parameters;
    try {
      const { data } = await axiosConfig.get(
        `StaffService/GetByStaffId/${pageNumber || 1}/${
          pageSize || 1000
        }/${staffId}`
      );
      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IGetStaffServiceByIdParams = {
  staffServiceId: number;
};

export const fetchStaffServiceById = createAsyncThunk(
  "staffs/fetchStaffServiceById",
  async (parameters: IGetStaffServiceByIdParams, ThunkApi) => {
    const { staffServiceId } = parameters;
    try {
      const { data } = await axiosConfig.get(`StaffService/${staffServiceId}`);

      return data.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IPostStaffServiceParams = {
  type: IEditActionsApiMethodType;
  body: Object;
  fetchParams: IGetStaffServicesParams;
};

export const postStaffService = createAsyncThunk(
  "staffs/postStaffService",
  async (parameters: IPostStaffServiceParams, ThunkApi) => {
    const { type, body, fetchParams } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig[type]("StaffService", body);
      ThunkApi.dispatch(fetchStaffAllServices(fetchParams));
      ThunkApi.dispatch(clearStaffServiceById());

      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IDeleteStaffServiceParams = {
  staffServiceId: number;
  fetchParams: IGetStaffServicesParams;
};

export const deleteStaffService = createAsyncThunk(
  "staffs/deleteStaffService",
  async (parameters: IDeleteStaffServiceParams, ThunkApi) => {
    const { staffServiceId, fetchParams } = parameters;
    const promiseToast = toast.loading("Deleting...");
    try {
      const { data } = await axiosConfig.delete(
        `StaffService/${staffServiceId}`
      );
      ThunkApi.dispatch(fetchStaffAllServices(fetchParams));
      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);

export type IPostStaffHoursParams = {
  body: Object;
};

export const postStaffHours = createAsyncThunk(
  "staffs/postStaffHours",
  async (parameters: IPostStaffHoursParams, ThunkApi) => {
    const { body } = parameters;
    const promiseToast = toast.loading("Saving...");
    try {
      const { data } = await axiosConfig.post("StaffHours", body);

      toast.update(promiseToast, {
        render: data.resultMessage,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      return data;
    } catch (error: any) {
      toast.update(promiseToast, {
        render: error.response ? error.response.data.title : "Request Timeout!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return ThunkApi.rejectWithValue(error.response.resultMessage);
    }
  }
);
