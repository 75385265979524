import { FC, ReactNode } from "react";

// MUI
import { Container, Grid } from "@mui/material";

// types
import { RouteType } from "../App";
import MainHeader from "../components/headers/Main.headers";

type LayoutProps = {
  routes: RouteType[];
  children: ReactNode;
};

const Layout: FC<LayoutProps> = ({ routes, children }) => {
  return (
    <Grid maxWidth={900} mx="auto">
      <Grid
        display="grid"
        gridTemplateRows="56px 1fr"
        height="100vh"
        sx={{
          backgroundImage: "url(/background-mobile.jpg)",
          backgroundSize: "cover",
        }}
      >
        <MainHeader routes={routes} />
        {children}
      </Grid>
    </Grid>
  );
};

export default Layout;
