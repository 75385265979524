import { FC } from "react";
import {
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
} from "@mui/material";

interface TextInputProps extends TextareaAutosizeProps {
  value: any;
  label: JSX.Element | string;
}

const BaseTextInput: FC<TextInputProps> = ({ value, label, ...props }) => {
  return (
    <>
      <TextareaAutosize
        style={{
          width: "100%",
          border: "1px solid",
          borderRadius: 5,
          height: "100%",
          padding: 16,
          outline: "none",
          overflow: "auto",
          backgroundColor: "#f7f6ff",
          borderColor: "#785FDC",
          fontSize: 12,
        }}
        minRows={3}
        maxRows={5}
        {...props}
        value={value}
      />
      <Typography
        position="absolute"
        fontSize={12}
        top={value ? -8 : 14}
        left={value ? 8 : 15}
        sx={{
          transition: "ease all 0.3s",
          bgcolor: value ? "white" : "transparent",
          color: "primary.main",
        }}
      >
        {label}
      </Typography>
    </>
  );
};

export default BaseTextInput;
