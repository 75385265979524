import React from "react";
// ? Mui
import { Grow } from "@mui/material";

type GrowPropTypes = {
  style?: Object;
  children?: React.ReactNode;
};

const GrowContainer: React.FC<GrowPropTypes> = ({ style, children }) => {
  return (
    <Grow in>
      <div
        style={{
          width: "100%",
          ...style,
        }}
      >
        {children}
      </div>
    </Grow>
  );
};

export default GrowContainer;
