import { useFormik, FormikErrors } from "formik";
import { DateObject, Value } from "react-multi-date-picker";
import * as yup from "yup";

// auth
type IAuthForm = {
  mobileNumber: string;
  deviceId: number;
  userName: string;
  userPass: string;
  otpCode: string;
  languageId: number;
  domain: string;
  version: string;
  licence: string;
};

export const useAuthForm = (submitHandler?: any) => {
  const initialValues: IAuthForm = {
    mobileNumber: "",
    deviceId: 1,
    userName: "",
    userPass: "",
    otpCode: "",
    languageId: 1,
    domain: "",
    version: "",
    licence: "",
  };
  const validate = (value: IAuthForm) => {
    let errors: FormikErrors<IAuthForm> = {};

    if (!value.userName) {
      errors.userName = "required*";
    }
    if (!value.userPass) {
      errors.userPass = "required*";
    }
    return errors;
  };

  return useFormik({
    initialValues,
    validate,
    onSubmit: submitHandler,
  });
};

type IStaffForm = {
  StaffId: number;
  ShopId: number;
  StaffName: string;
  StaffFamily: string;
  DisplayName: string;
  StaffEmail: string;
  Mobile: string;
  StaffStatus: number;
  StaffCalendarColor: string;
  IsOnlineBookable: boolean;
  AnnualSalary: number;
  WeeklyHours: number;
  HourlyRate: number;
  ExtraTimeHourlyRate: number;
  IsActive: boolean;
  Address: string;
  Bio: string;
  MemberOfId: number;
  NotificationEmail: boolean;
  NotiFicationSms: boolean;
  PayTypeId: number;
};

export const useStaffForm = (editStaff: IStaffForm | null, submit?: any) => {
  const initialValues = editStaff || {
    StaffId: 0,
    ShopId: 1,
    StaffName: "",
    StaffFamily: "",
    DisplayName: "",
    StaffEmail: "",
    Mobile: "",
    StaffStatus: 1,
    StaffCalendarColor: "#785FDC",
    IsOnlineBookable: false,
    AnnualSalary: 0,
    WeeklyHours: 0,
    HourlyRate: 0,
    ExtraTimeHourlyRate: 0,
    IsActive: false,
    Address: "",
    Bio: "",
    MemberOfId: 0,
    NotificationEmail: false,
    NotiFicationSms: false,
    PayTypeId: 0,
  };

  return useFormik({
    initialValues,
    onSubmit: submit,
  });
};
