import ReactDOM from "react-dom/client";
import App from "./App";
import { injectStore } from "./axiosConfig";
import { ToastContainer, Zoom } from "react-toastify";

// redux
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

// react-router
import { BrowserRouter } from "react-router-dom";

import "./font.css";
import "react-toastify/dist/ReactToastify.css";
import "./i18n/config";

injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Zoom}
        />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
