import { FC, ReactNode } from "react";

import { Route, Routes, useLocation } from "react-router";
import PageNotFound from "../../pages/errors/PageNotFound.error";

type RoutesContainerPropType = {
  children: ReactNode;
  FallbackComponent?: any;
};

const RoutesContainer: FC<RoutesContainerPropType> = ({
  children,
  FallbackComponent = <PageNotFound />,
}) => {
  return (
    <Routes>
      <Route path="*" element={FallbackComponent} />
      {children}
    </Routes>
  );
};

export default RoutesContainer;
