import React, { FC, useEffect, useRef, useState } from "react";

// Mui
import { Box, Button, Grow } from "@mui/material";

// Components
import GrowContainer from "../containers/Grow.containers";

type BaseFileInputPropTypes = {
  attachment: any;
  setAttachment: any;
  name?: string;
  dimensions?: Object;
  icon?: JSX.Element;
  isBanner?: boolean;
  dimY?: string | number;
  hasImage?: any;
};

const BaseFileInput: FC<BaseFileInputPropTypes> = ({
  attachment,
  setAttachment,
  name,
  dimensions = "100%",
  icon,
  isBanner = false,
  dimY,
  hasImage = null,
}) => {
  // Hooks
  const inputFileRef = useRef(null);
  // States
  const [inputFileHovered, setInputFileHovered] = useState(false);
  const [preview, setPreview] = useState<string | null>(null);

  const imageChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    // const files = Array.from(e.target.files as Iterable<File>);
    const files = e.target.files as FileList;

    const file = files[0];
    if (file) {
      setAttachment(file);
    }

    if (files.length === 0) {
      setAttachment();
    }
  };

  useEffect(() => {
    let objectUrl: any;
    // create the preview

    if (attachment) {
      objectUrl = URL.createObjectURL(attachment);
      setPreview(objectUrl);
    } else {
      if (hasImage) {
        setPreview(hasImage);
      } else {
        setPreview(null);
      }
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [attachment, hasImage]);

  const hoverEnable = () => {
    setInputFileHovered((prev) => !prev);
  };

  return (
    <Box
      sx={{
        width: dimensions,
        height: isBanner ? dimY : 120,
        position: "relative",
        borderRadius: 2,
        overflow: "hidden",
        bgcolor: preview ? "transparent" : "inputBackground.main",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
        border: 1,
        borderColor: "primary.main",
      }}
      onMouseEnter={hoverEnable}
      onMouseLeave={hoverEnable}
    >
      {preview ? (
        <GrowContainer
          style={{
            position: "absolute",
            width: "98%",
            display: "flex",
          }}
        >
          <img
            src={preview}
            alt=""
            width="100%"
            height="100%"
            style={{ borderRadius: "6px" }}
          />
        </GrowContainer>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "inputBackground.main",
          }}
        >
          {icon}
        </Box>
      )}
      <Grow in={inputFileHovered}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0 , 0, 0.3)",
            position: "absolute",
            display: preview ? "none" : "flex",
            alignItems: "center",
            textAlign: "center",
            color: "grey.100",
            justifyContent: "center",
            borderRadius: 2,
          }}
        >
          Upload Picture
        </Box>
      </Grow>
      <Button
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          bgcolor: "transparent",
          zIndex: 99,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        component="label"
        // onKeyDown={(e) => e.keyCode === 32 && inputFileRef.current?.click()}
      >
        <input
          ref={inputFileRef}
          type="file"
          hidden
          name={name}
          accept="image/*"
          onChange={imageChangeHandler}
        />
      </Button>
    </Box>
  );
};

export default BaseFileInput;
