import { useEffect, FC } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./redux/store";
import { useAppSelector } from "./hooks/redux.hooks";
import { logoutUser } from "./redux/user/user.reducer";
import RoutesContainer from "./components/containers/Routes.containers";
import { Navigate, Route } from "react-router";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/Login";
import Layout from "./layout/Layout";
import routes from "./routes";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// MUI
import { ThemeProvider, CssBaseline } from "@mui/material";
import useTheme from "./theme/theme";

function App() {
  // hooks
  const dispatch = useDispatch<AppDispatch>();

  const { user, lastSignIn, themeSetting } = useAppSelector(
    (state) => state.user
  );

  const diffTime = lastSignIn ? Math.abs(new Date().getTime() - lastSignIn) : 0;

  const staySignedIn = Math.floor(diffTime / (1000 * 60 * 60)) < 8 ?? false;

  useEffect(() => {
    if (!staySignedIn) {
      dispatch(logoutUser());
    }
    // dispatch(fetchLookupValuesByLookupId({ lookupId: "1;2;3;4;5" }));
  }, []);

  useEffect(() => {
    const redirectTo = () => {
      if (window.innerWidth > 768) {
        // window.location.replace("http://booking.weeorder.co.uk");
      }
    };
    window.addEventListener("resize", redirectTo);
    return () => window.removeEventListener("resize", redirectTo);
  }, []);

  const theme = useTheme();

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });

  const cacheLtr = createCache({
    key: "css",
    prepend: true,
  });

  if (user)
    return (
      <CacheProvider
        value={themeSetting.direction === "rtl" ? cacheRtl : cacheLtr}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Layout routes={routes}>
            <AppRoutes routes={routes} />
          </Layout>
        </ThemeProvider>
      </CacheProvider>
    );
  else
    return (
      <CacheProvider
        value={themeSetting.direction === "rtl" ? cacheRtl : cacheLtr}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RoutesContainer FallbackComponent={<Navigate to="/login" />}>
            <Route path="/login" element={<LoginPage />} />
          </RoutesContainer>
        </ThemeProvider>
      </CacheProvider>
    );
}

export type ISubRoutes = {
  id: number;
  name: string;
  url: string;
  icon?: JSX.Element;
  component?: JSX.Element;
};

export type RouteType = {
  id: number;
  name: string;
  url: string;
  component?: JSX.Element;
  icon?: JSX.Element;
  subRoutes?: ISubRoutes[];
};

export type RoutesType = {
  routes: RouteType[];
};

const AppRoutes: FC<RoutesType> = ({ routes }) => {
  return (
    <RoutesContainer>
      <Route path="/" element={<Dashboard />} />
      <Route path="/login" element={<Navigate to="/" />} />
      {routes?.map((route, idx) => {
        const { url, component } = route;
        return <Route key={idx} element={component} path={`${url}/*`} />;
      })}
    </RoutesContainer>
  );
};

export default App;
