import Dashboard from "./pages/Dashboard";

import { ReactComponent as HomeIcon } from "./assets/icons/home-icon.svg";
import { ReactComponent as StaffsIcon } from "./assets/icons/staffs-icon.svg";
import { ReactComponent as ServicesIcon } from "./assets/icons/services-icon.svg";
import { ReactComponent as ShiftsIcon } from "./assets/icons/shifts-icon.svg";
import { ReactComponent as AvailabilityIcon } from "./assets/icons/availability-icon.svg";
import { ReactComponent as ClientsIcon } from "./assets/icons/clients-icon.svg";
import { ReactComponent as BookingIcon } from "./assets/icons/booking-icon.svg";

// pages
import Staff from "./pages/staffs/Staff";
// import Shift from "./pages/shifts/Shift";
// import Availability from "./pages/availability/Availability";
// import Service from "./pages/services/Service";
// import Customer from "./pages/customer/Customer";
// import Booking from "./pages/booking/Booking";
// import Test from "./pages/test/Test";

const routes = [
  {
    id: 1,
    name: "Home",
    url: "/",
    component: <Dashboard />,
    icon: <HomeIcon />,
  },
  {
    id: 2,
    name: "staffs",
    url: "/staffs",
    icon: <StaffsIcon />,
    component: <Staff />,
  },
  // {
  //   id: 3,
  //   name: "services",
  //   url: "/services",
  //   icon: <ServicesIcon />,
  //   component: <Service />,
  //   subRoutes: [
  //     {
  //       id: 1,
  //       name: "services",
  //       url: "/services/overview",
  //       icon: <ServicesIcon />,
  //       component: <Service />,
  //     },
  //     {
  //       id: 2,
  //       name: "categories",
  //       url: "/services/categories",
  //       icon: <ServicesIcon />,
  //       component: <Service />,
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   name: "shifts",
  //   url: "/shifts",
  //   icon: <ShiftsIcon />,
  //   component: <Shift />,
  // },
  // {
  //   id: 5,
  //   name: "availability",
  //   url: "/availability",
  //   icon: <AvailabilityIcon />,
  //   component: <Availability />,
  // },
  // {
  //   id: 6,
  //   name: "customer",
  //   url: "/customer",
  //   icon: <ClientsIcon />,
  //   component: <Customer />,
  // },
  // {
  //   id: 7,
  //   name: "order",
  //   url: "/order",
  //   icon: <BookingIcon />,
  //   component: <Booking />,
  // },
  // {
  //   id: 8,
  //   name: "test",
  //   url: "/test",
  //   icon: <BookingIcon />,
  //   component: <Test />,
  // },
];

export default routes;
