import { FC } from "react";

// MUI
import { Grid, Typography } from "@mui/material";

// redux
import { IStaffData } from "../../redux/staff/staff.actions";

type IStaffCardProps = {
  staff: IStaffData;
};

const StaffCard: FC<IStaffCardProps> = ({ staff }) => {
  return (
    <Grid
      bgcolor="white"
      container
      borderRadius={2}
      p={0.75}
      gap={2}
      alignItems="center"
    >
      <Grid item xs={2.5} height={100}>
        {/* <img
          src={`http://booking.weeorder.co.uk/uploadfiles/Images/${staff.staffImage}`}
          alt=""
          width="100%"
          height="100%"
          style={{ borderRadius: 4 }}
        /> */}
      </Grid>
      <Grid item xs={4}>
        <Typography fontSize={12}>
          {staff.staffName} {staff.staffFamil}
        </Typography>
        <Typography fontSize={12} noWrap>
          {staff.staffEmail}
        </Typography>
        <Typography fontSize={12}>{staff.mobile}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography fontSize={12}>{staff.address}</Typography>
      </Grid>
    </Grid>
  );
};

export default StaffCard;
